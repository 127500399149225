import React from "react"
import PropTypes from "prop-types"
import Poem from "../../../../components/poem"
import Stack from "../../../../components/stack"

const PoemHaelfteDesLebens = ({
  size,
  showTitle,
  hideFirstSection,
  hideSecondSection,
}) => {
  return (
    <Poem size={size}>
      <Stack space={6}>
        {showTitle && (
          <>
            <p>
              <strong>Hälfte des Lebens</strong>
            </p>
            <br />
          </>
        )}
        {!hideFirstSection && (
          <>
            <p>Mit gelben Birnen hänget</p>
            <p>Und voll mit wilden Rosen</p>
            <p>Das Land in den See,</p>
            <p>Ihr holden Schwäne,</p>
            <p>Und trunken von Küssen</p>
            <p>Tunkt ihr das Haupt</p>
            <p>Ins heilignüchterne Wasser.</p>
          </>
        )}
        {!hideSecondSection && (
          <>
            <p>Weh mir, wo nehm’ ich, wenn</p>
            <p>Es Winter ist, die Blumen, und wo</p>
            <p>Den Sonnenschein,</p>
            <p>Und Schatten der Erde?</p>
            <p>Die Mauern stehn</p>
            <p>Sprachlos und kalt, im Winde</p>
            <p>Klirren die Fahnen. </p>
          </>
        )}
      </Stack>
    </Poem>
  )
}

PoemHaelfteDesLebens.propTypes = {
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
  showTitle: PropTypes.bool,
  hideFirstSection: PropTypes.bool,
  hideSecondSection: PropTypes.bool,
}

PoemHaelfteDesLebens.defaultProps = {
  size: [3, 3, 4],
  showTitle: false,
  hideFirstSection: false,
  hideSecondSection: false,
}

export default PoemHaelfteDesLebens
