import React from "react"
import { navigate } from "gatsby"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"

import LayoutCourse from "../../../../../layouts/course"

import Checkbox from "../../../../../components/checkbox"
import Column from "../../../../../components/column"
import Columns from "../../../../../components/columns"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Note from "../../../../../components/note"
import PaperStack from "../../../../../components/paper-stack"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"

import PoemHaelfteDesLebens from "../../../../../content/kurse/haelfte-des-lebens/01-zwei-haelften/poem-haelfte-des-lebens"
import Paragraph from "../../../../../components/paragraph"

const Page = () => {
  const { submitForm } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })

  return (
    <LayoutCourse
      as="form"
      onSubmit={(event) => {
        event.preventDefault()
        submitForm({
          event,
          courseId: "haelfte-des-lebens",
          chapterId: "01-zwei-haelften",
          taskId: "sinne",
        })
        navigate(
          "/kurse/haelfte-des-lebens/01-zwei-haelften/sinne/zusammenfassung"
        )
      }}
      footer={<CourseFooterNext type="submit" value="submit" />}
      navigation={navigation}
    >
      <Seo title="Mit allen Sinnen lesen" />
      <Stack>
        <Heading as="h2" level={2}>
          Mit allen Sinnen lesen
        </Heading>
        <Paragraph>
          Wie passt das zu den Sinnen, die in den beiden Strophen angesprochen
          werden?
        </Paragraph>
        <Note variant="task">
          Wähle aus, welche Sinne in den beiden Strophen angesprochen werden.
          Pro Strophe sind mehere Antworten möglich.
        </Note>
        <Stack space={10}>
          <Heading level={5}>Hälfte des Lebens</Heading>
          <Columns alignY="center" space={12} collapse={[true, false]}>
            <Column width="2/3">
              <PaperStack>
                <PoemHaelfteDesLebens hideSecondSection />
              </PaperStack>
            </Column>
            <Column width="1/3">
              <Stack space={3}>
                <Checkbox name="q1-sehen" value={true}>
                  Sehen
                </Checkbox>
                <Checkbox name="q1-hoeren" value={true}>
                  Hören
                </Checkbox>
                <Checkbox name="q1-riechen" value={true}>
                  Riechen
                </Checkbox>
                <Checkbox name="q1-schmecken" value={true}>
                  Schmecken
                </Checkbox>
                <Checkbox name="q1-fuehlen" value={true}>
                  Fühlen
                </Checkbox>
              </Stack>
            </Column>
          </Columns>
          <Columns alignY="center" space={12} collapse={[true, false]}>
            <Column width="2/3">
              <PaperStack>
                <PoemHaelfteDesLebens hideFirstSection />
              </PaperStack>
            </Column>
            <Column width="1/3">
              <Stack space={3}>
                <Checkbox name="q2-sehen" value={true}>
                  Sehen
                </Checkbox>
                <Checkbox name="q2-hoeren" value={true}>
                  Hören
                </Checkbox>
                <Checkbox name="q2-riechen" value={true}>
                  Riechen
                </Checkbox>
                <Checkbox name="q2-schmecken" value={true}>
                  Schmecken
                </Checkbox>
                <Checkbox name="q2-fuehlen" value={true}>
                  Fühlen
                </Checkbox>
              </Stack>
            </Column>
          </Columns>
        </Stack>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
